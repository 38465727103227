.dashboard-container {
  display: grid;
  height: 100%;
  grid-template-columns: 70% 1fr;
  padding-bottom:64px;
  gap: 32px;
}

.dashboard-container .dashboard-welcome {
  width: 100%;
}

.dashboard-container .dashboard-welcome p {
  line-height: 32px;
  color: black;
  font-size: 16px;

  margin-bottom: 32px;
}

.dashboard-container .dashboard-welcome .fast-access-container {
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding-top: 64px;
}

.dashboard-container .dashboard-welcome .fast-access-container .fast-access {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.dashboard-container .dashboard-welcome .fast-access-container .fast-access p {
  font-size: 14px;
}

.dashboard-container .dashboard-welcome .fast-access-container .fast-access .fast-access-cta {
  font-size: 12px;
  font-weight: bold;
  padding: 16px;
}
