.stake-dashboard {
  display: grid;
  height: 100%;
  grid-template-columns: 70% 1fr;
  padding-bottom: 64px;
  gap: 32px;
  width: 100%;
}

.stake-dashboard .stake-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.stake-container .stake-title {
  padding-bottom: 14px;
  padding-right: 32px;
}

.stake-container .stake-inputs-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
}

.stake-container .stake-inputs-container .input-helpers {
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 12px;
  right: 12px;
}

.stake-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.stake-header a {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.tiers-description {
  line-height: 3em;
}