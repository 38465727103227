.hidden {
  display: none;
}

.loan-application-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 1%;
}

.applicant-data {
  width: 35%
}

.masa-pool {
  width: 58%;
}

.w-70 {
  width: 70%;
}

.border-bottom {
  border-bottom: 0.5px solid #ececec;
}

.justify-end {
  justify-content: end;
}

.text-success {
  color: #219653;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pl-2 {
  padding-left: 2px;
}

.w-1\/4{
  width: 25%;
}

.bg-gray-200 {
  background-color:#F0F1F1;
  width: 100%;
}

.m-4 {
  margin: 4rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.h-100 {
  height: 100px;
}

.dimensions {
  width: 175px;
  height: 40px;
}

.-my-6 {
  width: auto;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  margin-bottom: -1.5rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.text-dark-gray {
  color: #404040;
}

.mr-8 {
  margin-right: 2rem;
}

.h-6 {
  height: 1rem;
}

.border-2 {
  border: 2px solid #404040;
  border-radius: 2px;
}

.content-between {
  justify-content: space-between;
}
