.masa-stats {
  overflow: auto;
  padding-top: 0;
  height: fit-content;
}
.masa-stats .masa-stats-subtitle {
  margin: 21px 0;
}
.masa-stats .masa-stats-value {
  font-size: 14px;
}
.masa-stats .masa-stats-value span {
  font-weight: bold;
  color: #696f79;
}

.masa-stats a .masa-stats-value {
  font-style: underline;
  color: #494949;
}
